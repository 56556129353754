html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 500px;
  padding-right: 500px;
  background: #f5f5f5;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.sidebar {
  top: 62px;
  width: 240px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #f8f9fa;
  position: fixed;
  height: 100%;
}
.sidebar-subitem-text {
  font-size: 0.8rem;
}
.sidebar-item-expand-arrow {
  font-size: 1.2rem !important;
}

.sidebar-item-expand-arrow-expanded {
  color: #2196f3;
  font-weight: bold;
}
.sidebar-item-icon {
  margin-right: 6px;
}
.inline {
  display: inline-block;
}

.form-control-small {
  width: 100px;
  display: inline;
}

th {
  background: #ffffff;
}
tr:nth-child(odd) {
  background: #7c7c7c;
}
tr:nth-child(even) {
  background: #ffffff;
}
.title {
  background: linear-gradient(#2196f3, #f5f5f5);
}
.questionParam {
  top: 100px;
  bottom: 10px;
  left: 270px;
  right: 10px;
  margin: auto;
  position: absolute;
  box-sizing: border-box;
}
.options {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.questionParam .tabs {
  padding: 20px;
  background-color: #ffffff;
  border-width: 0.2rem 0.2rem 0.2rem;
  border-radius: 8px 8px 8px 8px;
  border-color: rgb(236, 236, 236);
  border-style: solid;
}
.questionParam .categories {
  height: 600px;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}
.questionParam .buttons {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.questionParam .mainCat {
  flex-grow: 1;
}
.questionParam .other {
  flex-grow: 1;
}
.home {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  position: fixed;
  box-sizing: border-box;
  overflow-y: scroll;
}
.landing-page {
  top: 63px;
  bottom: 0px;
  left: 240px;
  right: 0px;
  margin: auto;
  position: absolute;
  box-sizing: border-box;
}
.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.cards .card {
  height: 230px;
  width: 20rem;
  margin-right: 30px;
  margin-bottom: 30px;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.resetPage {
  margin: auto;
  top: 50px;
  bottom: 10px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.reset {
  max-width: 1300px;
  margin: auto;
  top: 100px;
  bottom: 10px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.reset .reset-form {
  max-width: 500px;
  text-align: left;
  margin: auto;
  top: 70px;
  bottom: 10px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.faq {
  max-width: 1300px;
  margin: auto;
  top: 150px;
  bottom: 10px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.faq .questions {
  max-width: 1000px;
  margin: auto;
  top: 240px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.profile {
  padding: 50px;
  display: flex;
  flex-flow: column;
  position: absolute;
  text-align: left;
  top: 30px;
  left: 0px;
  right: 0px;
  bottom: 2px;
  height: 100px;
}
.results {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.results-container {
  display: flex;
  max-width: 1500px;
  position: relative;
  height: 300px;
  left: 0px;
  right: 0px;
  top: 100px;
  margin: auto;
  flex-flow: column;
  font-weight: bold;
  text-align: center;
}
.timer {
  text-align: center;
  max-width: 600px;
  margin: auto;
  padding: 0.5rem;
  border-radius: 0px 0px 8px 8px;
  border-style: solid;
}

.results-question {
  padding-top: 10px;
  text-align: left;
}

.results-solution {
  text-align: left;
  margin-top: -10px;
}

.sidenav {
  width: 200px;
  position: fixed;
  z-index: 1;
  top: 90px;
  left: 10px;
  background: #eee;
  overflow-x: hidden;
  padding: 8px 0;
}
.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #2196f3;
  display: block;
}

.sidenav a:hover {
  color: #064579;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
.sign-in {
  background-image: url(trianglify-export.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 200px;
  overflow-y: scroll;
}
.maintainer {
  width: 700px;
  top: 150px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}
.form-signin {
  width: 700px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.form {
  margin: auto;
  padding: 10px;
}
.questionPage {
  margin: auto;
  top: 60px;
  bottom: 0px;
  left: 270px;
  right: 20px;
  position: absolute;
}
.questionPage .question {
  text-align: left;
}
.questionPage .radio {
  display: flex;
  justify-content: left;
}
.radio-position {
  padding-top: 10px;
  padding-right: 550px;
}

.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.center {
  display: flex;
  justify-content: center;
}
.nounderline {
  text-decoration: none !important;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin select:required:invalid {
  color: #666;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.svg-inline--fa {
  margin-right: 7px;
}
.vertical {
  border-left: 1px solid;
  height: 410px;
  border-left-color: rgba(0, 0, 0, 0.1);
  left: 50%;
}
